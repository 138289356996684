<template>
  <div>
    <b-container>
      <b-row class="mt-5 mb-3">
        <b-col md="6">
          <h4>Persönliche Angaben</h4>
        </b-col>

        <b-col md="3"
          ><b-button
            variant="warning"
            :disabled="!orderList || minimumOrderPrice > totalPrice || !adresse"
            @click="sendOrder()"
            ><font-awesome-icon
              icon="forward"
              class="mr-4"
              size="lg"
            />Bestellung abschliessen</b-button
          >
          <ul class="list-inline">
            <li class="list-inline-item">
              <p class="typo__p" v-if="submitStatus === 'ERROR'">
                Bitte füllen Sie das Formular korrekt aus
              </p>
              <p class="typo__p" v-if="minimumOrderPrice > totalPrice">
                Die Mindestbestellung für Ihre Stadt wurde nicht erreicht. Bitte
                fügen Sie mehr Artikel zu Ihrem Warenkorb hinzu.
              </p>
            </li>
            <!-- Show backend response only if error -->
            <li class="list-inline-item">
              <p v-if="backendresponse !== 'OK'" class="typo__p">
                {{ backendresponse }}
              </p>
            </li>
          </ul>
        </b-col>
      </b-row>
      <form ref="form" @submit.prevent="sendEmail">
        <b-row>
          <b-col class="mt-3" md="6">
            <div
              class="form-group"
              :class="{ 'form-group--error': $v.vorname.$error }"
            >
              <label class="form__label" for="">Vorname</label>
              <input
                class="form__input form-control"
                v-model.trim="$v.vorname.$model"
                name="vorname"
                type="text"
              />
            </div>
            <div class="error" v-if="!$v.vorname.required">
              Feld ist erforderlich
            </div>
            <div class="error" v-if="!$v.vorname.minLength">
              Muss mindestens
              {{ $v.vorname.$params.minLength.min }} Buchstaben haben.
            </div>
            <div
              :class="{ 'form-group--error': $v.name.$error }"
              class="form-group"
            >
              <label for="">Name</label>
              <input
                v-model.trim="$v.name.$model"
                name="name"
                type="text"
                class="form-control"
              />
            </div>
            <div class="error" v-if="!$v.name.required">
              Feld ist erforderlich
            </div>
            <div class="error" v-if="!$v.name.minLength">
              Muss mindestens
              {{ $v.name.$params.minLength.min }} Buchstaben haben.
            </div>

            <div
              :class="{ 'form-group--error': $v.adresse.$error }"
              class="form-group mt-3"
            >
              <label for="">Adresse</label>
              <input
                :disabled="delivery_method == 'Abholung'"
                v-model.trim="$v.adresse.$model"
                type="text"
                name="adresse"
                class="form-control"
              />
            </div>

            <div class="error" v-if="!$v.adresse.required">
              Feld ist erforderlich
            </div>
            <div class="error" v-if="!$v.adresse.minLength">
              Muss mindestens
              {{ $v.adresse.$params.minLength.min }} Ziffern haben.
            </div>

            <div
              :class="{ 'form-group--error': $v.hausnummer.$error }"
              class="form-group mt-3"
            >
              <label for="">Hausnummer</label>
              <input
                v-model.trim="$v.hausnummer.$model"
                type="text"
                name="hausnummer"
                class="form-control"
              />
            </div>

            <div class="error" v-if="!$v.hausnummer.required">
              Feld ist erforderlich
            </div>
            <div class="error" v-if="!$v.hausnummer.minLength">
              Muss mindestens
              {{ $v.hausnummer.$params.minLength.min }} Ziffern haben.
            </div>

            <div
              :class="{ 'form-group--error': $v.delivery_method.$error }"
              class="form-group"
            >
              <label for="">Wie möchten Sie Ihre Bestellung erhalten?</label>
              <select
                v-model="$v.delivery_method.$model"
                name="delivery_method"
                id="delivery_method"
                class="form-control"
              >
                <option value="" disabled>Metode Wählen</option>
                <option value="Abholung" :disabled="!isStoreOpen()">
                  Abholen
                </option>
                <option value="Lieferung">Lieferen</option>
              </select>
            </div>
            <div class="error" v-if="!$v.delivery_time.required">
              Feld ist erforderlich
            </div>
            <div
              :class="{ 'form-group--error': $v.delivery_method.$error }"
              class="form-group"
            >
              <label for="">Wann möchten Sie Ihre Bestellung erhalten?</label>
              <select
                v-model="$v.delivery_time.$model"
                name="lieferung_zeit"
                id="lieferung_zeit"
                class="form-control"
              >
                <option value="" disabled>Zeit Wählen</option>
                <option value="Sofort" :disabled="!isStoreOpen()">
                  Sofort
                </option>
                <option
                  v-for="(option, index) in deliveryOptions"
                  :key="index"
                  :value="option"
                >
                  {{ option }}
                </option>
              </select>
            </div>
            <div class="error" v-if="!$v.delivery_time.required">
              Feld ist erforderlich
            </div>
          </b-col>
          <b-col md="6" class="mt-3">
            <div
              :class="{ 'form-group--error': $v.ort.$error }"
              class="form-group"
            >
              <label for="ort">Ort</label>
              <select
                v-model="$v.ort.$model"
                name="ort"
                id="ort"
                class="form-control"
              >
                <option value="" disabled>Ort Wahlen</option>
                <option
                  v-for="location in locations"
                  :key="location.id"
                  :value="location.name"
                >
                  {{ location.name }}
                </option>
              </select>
              <div class="error" v-if="!$v.ort.required">
                Feld ist erforderlich
              </div>
            </div>

            <div
              :class="{ 'form-group--error': $v.telefonnummer.$error }"
              class="form-group"
            >
              <label for="">Telefonnummer</label>
              <input
                v-model.trim="$v.telefonnummer.$model"
                type="number"
                name="telefonnummer"
                class="form-control"
              />
            </div>
            <div class="error" v-if="!$v.telefonnummer.required">
              Feld ist erforderlich
            </div>
            <div class="error" v-if="!$v.telefonnummer.minLength">
              Muss mindestens
              {{ $v.telefonnummer.$params.minLength.min }} Ziffern haben.
            </div>

            <div
              :class="{ 'form-group--error': $v.email.$error }"
              class="form-group"
            >
              <label for="">E-mail</label>
              <input
                v-model.trim="$v.email.$model"
                type="text"
                name="email"
                class="form-control"
              />
            </div>
            <div class="error" v-if="!$v.email.required">
              Feld ist erforderlich
            </div>
            <div class="error" v-if="!$v.email.minLength">
              Muss mindestens
              {{ $v.email.$params.minLength.min }} Buchstaben haben.
            </div>
            <!-- hidden input just for emails -->
            <input
              v-model="orderList"
              type="text"
              name="order"
              class="form-control d-none"
            />
            <input
              v-model="totalPrice"
              type="text"
              name="preis"
              class="form-control d-none"
            />

            <div
              :class="{ 'form-group--error': $v.zahlungsmetode.$error }"
              class="form-group"
            >
              <label for="zahlungsmetode">Zahlungsmetode vor ort</label>
              <select
                v-model="$v.zahlungsmetode.$model"
                name="zahlungsmetode"
                id="zahlungsmetode"
                class="form-control"
              >
                <option value="" disabled>Metode Wahlen</option>
                <option value="Bargeld">Bargeld</option>
                <option value="Twint">Twint</option>
                <option value="Kreditkarte">Kredit karte</option>
              </select>
              <div class="error" v-if="!$v.zahlungsmetode.required">
                Feld ist erforderlich
              </div>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.error {
  color: red;
  font-size: 10px;
  margin-bottom: 15px;
  margin-top: -10px;
}
.typo__p {
  font-size: 10px;
  color: red;
}
</style>

<script>
import { mapState } from "vuex";
import axios from "axios";
import moment from "moment";
import { required, minLength } from "vuelidate/lib/validators";
import store from "../store/index";
import emailjs from "@emailjs/browser";
export default {
  name: "PersonalInfos",
  mounted() {
    this.getLocations();
    this.getStatus();
  },
  data() {
    return {
      vorname: this.$cookies.get("vorname", this.vorname),
      name: this.$cookies.get("name", this.name),
      adresse: this.$cookies.get("adresse", this.adresse),
      telefonnummer: this.$cookies.get("telefonnummer", this.telefonnummer),
      email: "",
      submitStatus: null,
      locations: [],
      ort: "",
      zahlungsmetode: "",
      delivery_method: "",
      delivery_time: "",
      status: null,
      hausnummer: "",
    };
  },
  validations: {
    vorname: {
      required,
      minLength: minLength(3),
    },
    name: {
      required,
      minLength: minLength(3),
    },
    ort: {
      required,
      minLength: minLength(3),
    },
    telefonnummer: {
      required,
      minLength: minLength(10),
    },
    hausnummer: {
      required,
      minLength: minLength(1),
    },
    email: {
      required,
      minLength: minLength(5),
    },
    adresse: {
      required,
      minLength: minLength(3),
    },
    zahlungsmetode: {
      required,
      minLength: minLength(3),
    },
    delivery_time: {
      required,
      minLength: minLength(3),
    },
    delivery_method: {
      required,
      minLength: minLength(3),
    },
  },
  computed: {
    ...mapState(["api_url", "cartItems", "orderList", "backendresponse"]),
    totalPrice() {
      let sum = 0;
      this.cartItems.forEach(function (cart) {
        sum += cart.price;
      });
      return sum;
    },
    minimumOrderPrice() {
      const locationName = this.ort;
      for (let i = 0; i < this.locations.length; i++) {
        if (this.locations[i].name === locationName) {
          return this.locations[i].minimum_order_price;
        }
      }
      return null;
    },
    deliveryOptions() {
      let options = [];
      let currentTime = moment();

      if (!this.status) {
        console.error("this.status is null or undefined");
        return options;
      }

      const currentDay = moment().format("dddd").toLowerCase();
      const from1 = moment(this.status[currentDay + "_from_1"], "HH:mm");
      const to1 = moment(this.status[currentDay + "_to_1"], "HH:mm");
      const from2 = moment(this.status[currentDay + "_from_2"], "HH:mm");
      const to2 = moment(this.status[currentDay + "_to_2"], "HH:mm");

      if (
        !from1.isValid() ||
        !to1.isValid() ||
        !from2.isValid() ||
        !to2.isValid()
      ) {
        console.error(
          `Opening hours for ${currentDay} are not fully defined or invalid`
        );
        return options;
      }

      let nextTime;

      // Determine the next available starting time
      if (currentTime.isBetween(from1, to1, null, "[]")) {
        nextTime = currentTime.clone().add(30, "minutes"); // Currently within first opening period
      } else if (currentTime.isBetween(from2, to2, null, "[]")) {
        nextTime = currentTime.clone().add(30, "minutes"); // Currently within second opening period
      } else if (currentTime.isBefore(from1)) {
        nextTime = from1.clone(); // Before first opening period
      } else if (currentTime.isAfter(to1) && currentTime.isBefore(from2)) {
        nextTime = from2.clone(); // Between first and second opening periods
      } else if (currentTime.isAfter(to2)) {
        return options; // After second opening period
      }

      // Round up nextTime to the nearest 15-minute interval if within open hours
      if (nextTime.minute() % 15 !== 0) {
        nextTime.add(15 - (nextTime.minute() % 15), "minutes");
        nextTime.second(0);
      }

      // Generate available time slots
      while (nextTime.isBefore(moment().endOf("day"))) {
        if (
          nextTime.isBetween(from1, to1, null, "[]") ||
          nextTime.isBetween(from2, to2, null, "[]")
        ) {
          options.push(nextTime.format("HH:mm"));
          nextTime.add(15, "minutes");
        } else {
          // Once out of the valid period, skip to the next valid period if applicable
          if (nextTime.isAfter(to1) && nextTime.isBefore(from2)) {
            nextTime = from2.clone();
          } else {
            break; // No more valid periods within the day
          }
        }
      }

      return options;
    },
  },
  watch: {
    delivery_method(newValue) {
      if (newValue === "Abholung") {
        this.adresse = "Abholung";
      } else {
        this.adresse = "";
      }
    },
  },
  methods: {
    isStoreOpen() {
      const currentTime = moment();
      const currentDay = moment().format("dddd").toLowerCase();
      const from1 = this.status[currentDay + "_from_1"];
      const to1 = this.status[currentDay + "_to_1"];
      const from2 = this.status[currentDay + "_from_2"];
      const to2 = this.status[currentDay + "_to_2"];

      if (!from1 || !to1 || !from2 || !to2) {
        return false;
      }

      return (
        currentTime.isBetween(
          moment(from1, "HH:mm"),
          moment(to1, "HH:mm"),
          "minutes",
          "[]"
        ) ||
        currentTime.isBetween(
          moment(from2, "HH:mm"),
          moment(to2, "HH:mm"),
          "minutes",
          "[]"
        )
      );
    },
    sendOrder() {
      axios
        .post(this.api_url + "/api/orders", {
          data: {
            vorname: this.vorname,
            name: this.name,
            adresse: this.adresse,
            hausnummer: this.hausnummer,
            ort: this.ort,
            telefonnummer: this.telefonnummer,
            bestellung: this.orderList,
            gesamtpreis: this.totalPrice,
            email: this.email,
            delivery_method: this.delivery_method,
            delivery_time: this.delivery_time,
          },
        })
        .then(function (response) {
          store.commit("SET_BACKENDRESPONSE", response.statusText);
        })
        .catch(function (error) {
          store.commit("SET_BACKENDRESPONSE", error.response.statusText);
        });
      // Validate the form
      this.$v.$touch();
      //wait for backend response
      setTimeout(() => {
        // if front or || backend validation is invalid..
        if (this.$v.$invalid) {
          this.submitStatus = "ERROR";
        } else {
          store.commit("RESET_CART_ITEMS");
          store.commit("SET_SUCCESSFUL_ORDER_MSG");
          this.sendEmail();
          this.$router.push("/");
        }
      }, 500);
      // Name,adress etc autofill with cookies
      this.$cookies.set("vorname", this.vorname);
      this.$cookies.set("name", this.name);
      this.$cookies.set("adresse", this.adresse);
      this.$cookies.set("ort", this.ort);
      this.$cookies.set("telefonnummer", this.telefonnummer);
    },
    formatTime(time) {
      return time.format("HH:mm");
    },
    getLocations() {
      axios.get(this.api_url + "/api/locations?populate=*").then((response) => {
        this.locations = response.data.data.map((item) => item.attributes);
      });
    },
    getStatus() {
      axios.get(this.api_url + "/api/status").then((response) => {
        this.status = response.data.data.attributes;
      });
    },
    sendEmail() {
      emailjs.sendForm(
        "service_1x1zg0l",
        "template_rq0v9na",
        this.$refs.form,
        "user_axUHCmI2YsASM2PhF7PlY"
      );
    },
  },
};
</script>
